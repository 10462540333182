.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f1efe7;
}

.App-link {
  color: #61dafb;
}

.App {
  text-align: center;
}

html {
  background-color: #000;
}

.NavLeft {
  float: left;
  padding: 3vh;
  margin: 3vh;
  list-style-type: none;
  font-size: 28px;
  color: #f1efe7;
}

.NavRight {
  float: right;
  padding: 3vh;
  margin: 3vh;
  list-style-type: none;
  font-size: 28px;
  color: rgba(255, 255, 255, 0);
}

.MainContent {
  background-color: black;
}

.Wallpaper {
  background-color: black;
  background-image: url('../assets/ivan.svg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: sticky;
  padding: auto;
}

/* Medium devices (tablets) */
@media (max-width: 899px) {
  .Wallpaper {
    background-color: black;
    background-image: url('../assets/ivan.svg');
    background-size: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: sticky;
    padding: auto;
  }
}

/* Small devices (mobile) */
@media (max-width: 599px) {
  .Wallpaper {
    background-color: black;
    background-image: url('../assets/ivan.svg');
    background-size: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; /* Adjust as necessary */
    position: sticky;
    padding: auto;
  }
}

/* iPhone X and newer (including iPhone 11, 12, 13, 14 series) */
@media only screen 
  and (device-width: 375px) 
  and (device-height: 812px) 
  and (-webkit-device-pixel-ratio: 3),
only screen 
  and (device-width: 812px) 
  and (device-height: 375px) 
  and (-webkit-device-pixel-ratio: 3) {
    .Wallpaper {
      background-color: black;
      background-image: url('../assets/ivan.svg');
      background-size: 200vh;
      background-position: center;
      background-repeat: no-repeat;
      position: sticky;
      padding: auto;
    }
}


ul li {
  cursor: pointer;
  font-weight: bold;
}

.PortfolioGrid, .ExperienceGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  background-color: black;
  color: #f1efe7;
  margin-top: 5vh;
  margin-bottom: 10vh;
  padding-bottom: 10vh;
}

/* Small devices (mobile) */
@media (max-width: 599px) {
  .PortfolioItem, .ExperienceItem {
    border: 1px solid #f1efe7; 
    background-color: black;
    color: #f1efe7;
    margin-top: 5vh;
    margin-bottom: 10vh;
    padding: 10vh;
    font-size: 12px;
  }
}

.PortfolioItem, .ExperienceItem {
  border: 1px solid #f1efe7; 
  background-color: black;
  color: #f1efe7;
  margin-top: 5vh;
  margin-bottom: 10vh;
  margin-left: 7vh;
  margin-right: 7vh;
  padding: 1vh;
}

.PortfolioItem:hover, .ExperienceItem:hover {
  border: 1px solid black; 
  background-color: #f1efe7;
  color: black;
  margin-top: 5vh;
  margin-bottom: 10vh;
  margin-left: 7vh;
  margin-right: 7vh;
  padding: 1vh;
  
}

.ItemHeader {
  color: #f1efe7;
  padding-top: 25vh;
}